.Container {
  position: relative;
  margin-right: 1rem;
}

.Button {
  height: 32px;
  min-height: 32px;
  user-select: none;
  border: 1px solid black;
  background-color: white;
  transition: background-color 130ms ease-in;
  border-radius: 4px;
}

.Button:hover {
  cursor: pointer;
  background-color: lightgray;
}

.Open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 44px;
  left: 0;
  transform: translateX(-25%);
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;
  max-height: 400px;
  overflow: auto;
}

.Image {
  height: 100px;
  align-self: baseline;
  background-color: lightgray;
  margin-bottom: 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition:
    box-shadow 130ms ease-in,
    transform 130ms ease-in;
}

.Image:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.ImagePlaceholder {
  height: 100px;
  background-color: lightgray;
  margin-bottom: 1rem;
}
