.List {
  display: flex;
  list-style: none;
  margin: 0 -1rem;
  overflow: auto;
  padding: 0 1rem 1rem 1rem;
}

@media (min-width: 1024px) {
  .List {
    margin: 0 auto;
  }
}

.ListItem {
  margin: 0 1rem 0 0;
  padding: 0;
  cursor: pointer;
  min-width: 140px;
  max-width: 140px;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.13s ease-in;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.ListItem:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.Image {
  width: 100%;
  aspect-ratio: 160 / 80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Name {
  padding: 0.5rem;
  text-align: center;
  font-size: 13.3px;
}
