.Form {
  display: inline-flex;
  margin-left: 1rem;
}

.Select {
  padding: 0.5rem;
  width: 200px;
  margin-right: 0.5rem;
}
