.Container {
  position: relative;
}

.Button {
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  height: 32px;
}

.Button:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.RangeContainer {
  display: flex;
  position: absolute;
  top: 44px;
  left: 0;
  transform: translateX(-25%);
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;
}

.Range {
  margin-right: 0 0.5rem 0 0;
  color: red;
}
