.Button {
  height: 32px;
  user-select: none;
  border: 1px solid black;
  background-color: white;
  transition: background-color 130ms ease-in;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.Button:hover {
  cursor: pointer;
  background-color: lightgray;
}

.Button:last-child {
  margin-right: 0;
}

.Active {
  background-color: lightgray;
}
