.Container {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 999;
}

.Button {
  background-color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
  display: flex;
  padding: 0.5rem;
}

.Button:hover {
  cursor: pointer;
}

.Cogwheel {
  transform-origin: center;
  font-size: 14px;
  line-height: 14px;
}

.Button:hover .Cogwheel {
  animation: spin 4s linear infinite;
}

.ForceSpin {
  animation: spin 4s linear infinite;
}

.OptionsContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1rem;
  margin-top: 0.5rem;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
