.Canvas {
  display: block;
  user-select: none;
  box-sizing: content-box;
}

.Interaction {
  box-sizing: content-box;
  transition: box-shadow ease-in 130ms;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0);
}

.Interaction:hover {
  box-shadow: 0px 0px 0px 3px rgba(0, 144, 237, 0.4);
  cursor: pointer;
}

.Selected {
  box-shadow: 0px 0px 0px 3px rgba(0, 144, 237, 0.4);
}
