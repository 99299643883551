.Button {
  margin-right: 0.5rem;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  transition: background-color 130ms ease-in;
}

.Button:hover {
  background-color: lightgray;
  cursor: pointer;
}

.Button:last-child {
  margin-right: 0;
}

.Active {
  background-color: lightgray;
}

.Bold {
  font-weight: 900;
}

.Italic {
  font-style: italic;
}

.Underline {
  text-decoration: underline;
}

.Strikeout {
  text-decoration: line-through;
}
