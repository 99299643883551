.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.ImageInput {
  display: flex;
  align-items: center;
}

.ImageInput:hover {
  cursor: pointer;
}

.ImageInput:hover .Text {
  background-color: rgba(0, 0, 0, 0.15);
}

.ImageInput:hover .ImagePreview {
  transform: scale(0.9);
}

.ImagePreview {
  display: flex;
  transition: transform 0.13s ease-in;
  max-width: 70px;
}

.FileInput {
  width: 0;
}

.Text {
  margin-left: 0.5rem;
  font-size: 13.3px;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.13s ease-in;
}

.Reset {
  margin: 0;
  font-size: 13.3px;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.13s ease-in;
  height: fit-content;
}

.Reset:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.Eyes {
  margin: 0;
  font-size: 13.3px;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.13s ease-in;
  height: fit-content;
}

.Eyes:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
