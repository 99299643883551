.Templates {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 6rem 1rem;
  background-color: white;
  width: 500px;
  z-index: 2;
  border-right: 1px solid darkgray;
  overflow: auto;
}

.H1 {
  margin: 0;
  padding: 0;
}

.H2 {
  margin: 1rem 0 1rem 0;
  padding: 0;
}

.Options {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Option {
  display: inline-block;
  margin: 0 1rem 1rem 0;
  padding: 0;
  max-width: calc(50% - 1rem);
}

.Option:nth-child(even) {
  margin-right: 0;
}

.Image {
  max-width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition:
    box-shadow 130ms ease-in,
    transform 130ms ease-in;
}

.Image:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
  cursor: pointer;
}

.Hidden {
  max-width: 44px;
}

.Show {
  margin: 0;
}

.Form {
  display: flex;
  margin-top: 0.5rem;
}

.Input {
  flex: 1;
}
