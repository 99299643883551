.Container {
  display: flex;
  flex-direction: row;
}

.Value {
  margin: 0 0.5rem;
  align-self: center;
}

.Button {
  font-size: 18px;
  width: 32px;
  height: 32px;
  font-weight: 900;
  user-select: none;
  padding: 0;
  border: 1px solid black;
  background-color: white;
  transition: background-color 130ms ease-in;
  border-radius: 4px;
}

.Button:hover {
  cursor: pointer;
  background-color: lightgray;
}
