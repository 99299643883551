.Container {
  padding-top: 1rem;
  margin-top: 0rem;
  padding-bottom: 3rem;
  border-top: 1px solid black;
}

.OptionLabel {
  display: flex;
  line-height: 1.5rem;
  align-items: center;
}

.Pre {
  padding: 0;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.Row {
  display: flex;
  margin-top: 1rem;
}
.Column {
  display: flex;
  flex: 0.33;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 1rem;
}

.Column:last-of-type {
  margin-right: 0;
}

.Button {
  margin-top: 1rem;
}

.Link {
  display: inline-block;
  line-height: 1.5rem;
  margin-top: 1rem;
}

.Label {
  display: block;
  margin-top: 1rem;
  line-height: 1.5rem;
}

.Input {
  padding: 0.5rem;
  width: calc(100% - 1.5rem);
  margin-bottom: 1rem;
}

.Editor {
  width: 100%;
}

.Error {
  color: red;
  font-weight: 900;
  padding: 1rem;
  border: 1px solid red;
}
