.ActionsList {
  margin-top: auto;
  display: flex;
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;
  justify-content: center;
}

.ActionListItem {
  margin: 0 0.5rem 0 0;
  padding: 0;
}

.ActionButton {
  margin: 0;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.13s ease-in;
}

.ActionButton:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
