.Container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .Container {
    flex-direction: row;
  }
}

.DesignContainer {
  position: relative;
}

.Design {
  position: relative;
  max-width: 100%;
  height: 400px;
  max-height: 400px;
  border-radius: 0.5rem;
  margin-right: 1rem;
  transition: opacity 0.3s ease-in 0.2s;
}

@media (min-width: 1024px) {
  .Design {
    width: 700px;
    max-width: 100%;
    height: 600px;
    max-height: 600px;
  }
}

.Form {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease-in 0.2s;
}

@media (min-width: 1024px) {
  .Form {
    width: stretch;
    max-width: 380px;
    margin-left: auto;
  }
}
