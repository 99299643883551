.Loading {
  position: absolute;
  top: 2px;
  left: 2px;
  animation: spin 4s linear infinite;
  z-index: 1;
}

@keyframes spin {
  50% {
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
  }
}
