.Label {
  margin-top: 0.5rem;
  line-height: 1.5rem;
}

.BarContainer {
  position: relative;
  height: 2rem;
}

.Bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.BarText {
  position: absolute;
  top: 0;
  left: 0.5rem;
  bottom: 0;
  z-index: 2;
  font-size: 16px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
}
