.ContextMenu {
  position: absolute;
  background-color: white;
  z-index: 9999;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.Button {
  margin: 0 0 0.5rem 0;
  cursor: pointer;
  background-color: white;
  border: 1px solid black;
  padding: 0 1rem;
  height: 32px;
  border-radius: 4px;
  transition: background-color 130ms ease-in;
}

.Button:last-child {
  margin-bottom: 0;
}

.Button:hover {
  background-color: lightgray;
}
