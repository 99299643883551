@keyframes fadeIn {
  from {
    background-color: rgba(255, 255, 255, 0);
  }
  to {
    background-color: rgba(255, 255, 255, 0.95);
  }
}

@keyframes formAnimation {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Dialog {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  padding-top: 100px;
  z-index: 9999999;
  border: none;
  animation: fadeIn 300ms ease-in;
}

.Button {
  margin: 1rem 0 0 0;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.13s ease-in;
}

.Button:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.Form {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 2rem;
  border: 1px solid black;
  height: fit-content;
  border-radius: 8px;
  background-color: white;
  transform: translateY(100px);
  opacity: 0;
  animation: formAnimation 200ms ease-out 130ms forwards;
}

.Label {
  display: flex;
  flex-direction: column;
}
