.Toolbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 0.5rem 0.5rem 0.5rem calc(76px + 0.5rem);
  background-color: white;
  border-bottom: 1px solid darkgray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
}

.Wrapper {
  margin-right: 1rem;
}
