.Input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TextArea {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 300px;
  min-height: 100px;
  min-width: 300px;
}

.Button {
  margin-right: 0.5rem;
}

.Row {
  display: flex;
}

.RenderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
