.Button {
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  height: 32px;
  min-height: 32px;
  display: inline-flex;
  padding: 0 0.5rem;
  box-sizing: border-box;
  font-size: 13.3333px;
  align-items: center;
}

.Button:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.Input {
  position: absolute;
  width: 1px;
  height: 1px;
}
