.Container {
  display: flex;
  flex-direction: column;
}

.Header {
  margin: 0 0 1rem 0;
}

.SurfaceName {
  margin: 0.5rem 0 1rem 0;
}

.Note {
  font-size: 0.9rem;
  line-height: 1rem;
  margin: 1rem 0 0 0;
}
