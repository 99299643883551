.Container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: white;
  z-index: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transform: translateY(-16px);
  opacity: 0;
  animation: dropdown 130ms ease-in forwards;
}

.FontStyleActive {
  filter: brightness(90%);
}

@keyframes dropdown {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
