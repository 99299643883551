.DesignArea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
  background-color: white;
}

.ImageUploading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  z-index: 1;
  background: grey;
  opacity: 0.3;
}

.ImageUploadingProgress {
  height: 100%;
  background-color: black;
  transition: width 100ms linear;
  width: 0px;
}
