.Previews {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.Panels {
  width: 250px;
  background-color: white;
}

.ThreeD {
  width: 400px;
  background-color: white;
}

.Scene {
  width: 400px;
  background-color: white;
}

.PreviewTypesContainer {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.Button {
  margin-right: 0.5rem;
}

.Button:last-child {
  margin-right: 0;
}
