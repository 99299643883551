.Nav {
  display: flex;
  background-color: #fcdde4;
  justify-content: center;
}

.Link {
  padding: 1rem;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 130ms ease;
  display: flex;
  align-items: center;
}

.Link:hover {
  background-color: #f8f8f8;
}

.LinkActive {
  background-color: #f8f8f8;
}

.Main {
  padding: 1rem;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
