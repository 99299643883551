.Container {
  position: relative;
}

.Button {
  border: 1px solid black;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
}

.OpenContainer {
  position: absolute;
  top: 44px;
  left: 0;
  transform: translateX(-50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 1rem 1rem;
  border: 1px solid black;
  border-radius: 4px;
}

.Label {
  display: flex;
  margin: 0 0 1rem 0;
  font-weight: 600;
  align-items: center;
}

.Label:last-child {
  margin: 0;
}

.LabelValue {
  width: 16px;
}

.Range {
  margin: 0 0 0 1rem;
  /* background-color: red; */
  appearance: none;
  height: 8px;
  border-radius: 4px;
}
