.Container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .Container {
    flex-direction: row;
  }

  .Editor {
    margin-right: 3rem;
    width: 40%;
    order: 1;
  }

  .Preview {
    order: 2;
  }
}

.Canvas {
  border: 1px solid black;
  box-sizing: border-box;
}

.Filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.ButtonsContainer {
  margin-top: 1rem;
}

.Button {
  margin-left: 1rem;
}
