.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  padding: 0 5px;
}

.Reset {
  margin: 0;
  font-size: 13.3px;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.13s ease-in;
  height: fit-content;
}

.Reset:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.Eyes {
  margin: 0;
  font-size: 13.3px;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.13s ease-in;
  height: fit-content;
}

.Eyes:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
